import React, { Suspense, useEffect } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const Login = React.lazy(() => import('@/pages/login/Content'));
const ForgotPassword = React.lazy(() => import('@/pages/forgot-password/Content'));
const ChangePassword = React.lazy(() => import('@/pages/change-password/Content'));
const Industry = React.lazy(() => import('@/pages/admin/Industry'));
const SelfService = React.lazy(() => import('@/pages/admin/Self-Service'));
const CompanyRegister = React.lazy(() => import('@/pages/register/company/CompanyRegister'));
const GoogleOAuthPostback = React.lazy(() => import('@/pages/sso/google-oauth-postback/Content'));
const FacebookOAuthPostback = React.lazy(() => import('@/pages/sso/facebook-oauth-postback/Content'));

function App() {
  
  const getCurrentTime = async () => {
    try {
      const config = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      };
      const res = await fetch(`/get_current_time/`, config);
      if (!res.ok) {
        throw res;
      }
      const contentType = res.headers.get('Content-Type');
      let responseData;
      if (contentType && contentType.indexOf('application/json') !== -1) {
        responseData = await res.json();
      } else {
        responseData = await res.text();
      }
      localStorage.setItem('datetime', new Date(responseData).toString());
    } catch (err: any) {
      const contentType = err.headers.get('Content-Type');
      let errData;
      if (contentType && contentType.indexOf('application/json') !== -1) {
        errData = await err.json();
      } else {
        errData = await err.text();
      }
      let finalErrorMessage = errData;
      console.error(finalErrorMessage);
    }
  };

  useEffect(() => {
    getCurrentTime();
  }, []);

  return (
    <Router>
      <Suspense fallback={<></>}>
        <Routes>
          <Route path='/signin' element={<Login />} />
          <Route path='/reset-password' element={<ForgotPassword />} />
          <Route path='/slippery/change-password/:code' element={<ChangePassword />} />
          <Route path='/industry' element={<Industry />} />
          <Route path='/self-service' element={<SelfService />} />
          <Route path='/register/company' element={<CompanyRegister />} />
          <Route path='/sso/google-oauth-postback' element={<GoogleOAuthPostback />} />
          <Route path='/sso/facebook-oauth-postback' element={<FacebookOAuthPostback />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
